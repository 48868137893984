<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section horizontal>
        <q-card-section
          class="column q-gutter-md justify-between"
          style="min-width:300px"
        >
        <div class="q-col-gutter-sm">
          <q-select
            outlined
            v-if="user.is_super_user == '1'"
            v-model="selMusyrif"
            label="Musyrif"
            :options="musyrif"
            @input="selSiswa=null;dataNilai= [];getSiswa()"
          />
          <q-select
            outlined
            label="Siswa"
            :options="siswa"
            v-model="selSiswa"
            @input="getNilai"
          />
        </div>

          <q-btn
            filled
            color="primary"
            label="SIMPAN CATATAN"
            :disable="selSiswa==null"
            @click="simpan"
          ></q-btn>
        </q-card-section>
        <q-markup-table
          flat
          bordered
          wrap-cells
          separator="horizontal"
          class="stickyTable bg-indigo-1 full-width bg-grey-4"
          style="height: calc(100vh - 197px);"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th>no</th>
              <th></th>
              <th>Nilai</th>
              <th>Catatan</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tbody
              v-for="(el, j) in dataNilai"
              :key="j"
              style="display: contents"
            >
              <tr>
                <td class="bg-indigo-3" colspan="3">
                  <b>ASPEK {{ el.aspek }}</b>
                </td>
                <td :rowspan="el.nilai.length + 1">
                  <q-input
                    stack-label
                    placeholder="Type here..."
                    bottom-slots
                    outlined
                    bg-color="white"
                    v-model="el.catatan"
                    filled
                    type="textarea"
                    autogrow
                    maxlength="250"
                  >
                    <template v-if="el.catatan" v-slot:hint
                      >{{ el.catatan.length }} / 250</template
                    >
                  </q-input>
                </td>
              </tr>
              <tr v-for="(val, i) in el.nilai" :key="i">
                <td>{{ val.number }}</td>
                <td>{{ val.nama }}</td>
                <td>{{ val.predikat }}</td>
              </tr>
            </tbody>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: "catatan",
      nilai: null,
      confirm: false,

      user: {},
      musyrif: [],
      selMusyrif: {
        value: null
      },

      searchTerm: "",
      catatan: "",
      siswa: [],
      selSiswa: null,
      dataNilai: []
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter(val => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    }
  },
  async mounted() {
    await this.getUser();
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
    await this.getSiswa();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getNilai() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/catatan/getnilai/${
          this.selSiswa.value
        }/${localStorage.getItem("jenjang")}/${localStorage.getItem(
          "id_tahun_ajaran"
        )}`
      );
      let count = 1;
      for (let aspek of resp.data) {
        for (let sub_aspek of aspek.nilai) {
          let a = sub_aspek.batas_a;
          let b = sub_aspek.batas_b;
          let c = sub_aspek.batas_c;
          let d = sub_aspek.batas_d;
          let nilai = parseFloat(
            (parseFloat(sub_aspek.nilai_musyrif) +
              parseFloat(sub_aspek.nilai_pekan)) /
              2
          ).toFixed(2);
          let predikat = "";
          if (nilai >= a) {
            predikat = "M";
          } else if (nilai >= b && nilai < a) {
            predikat = "B";
          } else if (nilai >= c && nilai < b) {
            predikat = "MBK";
          } else if (nilai >= d && nilai < c) {
            predikat = "MB";
          }
          sub_aspek.predikat = predikat;
          sub_aspek.number = count;
          count++;
        }
      }
      this.dataNilai = resp.data;
      this.$q.loading.hide();
    },
    async getSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/riwayat_absen/getsantri/${this.selMusyrif.value}`
      );
      this.siswa = resp.data;
      this.$q.loading.hide();
    },
    async simpan() {
      let kirim = [];
      for (let aspek of this.dataNilai) {
        let carier = {};
        carier.kelompok = aspek.aspek;
        carier.catatan = aspek.catatan;
        kirim.push(carier);
      }
      let nama = "";
      if (this.user.is_super_user == "1") {
        nama = this.selMusyrif.label;
      } else {
        nama = this.user.nama;
      }
      await this.$http.post(
        `/penilaian/catatan/add/${this.selSiswa.value}/${localStorage.getItem(
          "id_tahun_ajaran"
        )}/${nama}`,
        kirim
      );
      this.$q.notify({
        message: "Catatan Berhasil di Simpan",
        color: "positive"
      })
      await this.getNilai();
    }
  }
};
</script>

<style lang="scss" scoped></style>
